import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IdsSpinnerComponent } from '@shared/components/ids-spinner/ids-spinner.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '@environments/environment';
// eslint-disable-next-line max-len
import { API_AUTH_TOKEN, APP_API_URL, APP_NAME, APP_LOGO, IDS_API_URL, PLATFORM_API, PLATFORM_HOME, PLATFORM_VERSION, SSO_API_URL } from '@shared/injections';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AllEffects } from '@app/store/effects';
import { metaReducers, reducers } from './store/reducers';


@NgModule({
  declarations: [AppComponent, IdsSpinnerComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,

    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(AllEffects),
    StoreRouterConnectingModule.forRoot(),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    AuthModule,
    HttpClientModule,
    AppRoutingModule,
    MatIconModule,
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    { provide: APP_NAME, useValue: 'IDS Builder' },
    { provide: APP_LOGO, useValue: null },
    { provide: PLATFORM_API, useValue: environment.core.api },
    { provide: PLATFORM_VERSION, useValue: '4' },
    { provide: PLATFORM_HOME, useValue: `${environment.core.home}` },
    { provide: API_AUTH_TOKEN, useValue: '' },
    { provide: SSO_API_URL, useValue: environment.sso.api },
    { provide: IDS_API_URL, useValue: `${environment.apiBaseUrl}/v1` },
    { provide: APP_API_URL, useValue: `${environment.apiBaseUrl}/app/v1` },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-icons-sharp');
  }
}
